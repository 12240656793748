import styles from './videomessage.module.scss'
import { messageStatus } from "../../chat/Chat";
import { isPersianOrArabic } from '../../../utils/helperFunctions';
import CircularProgress from '../../CircularProgress/CircularProgress ';
import { useEffect, useState } from 'react';

const VideoMessage = ({ msg, uploadProgress }) => {
  // console.log('msg.status', msg.status);
  
  return (
    <div className={`${styles.message} ${msg.sender === 'me' ? styles.myMessage : styles.partnerMessage}`} style={{ textAlign: isPersianOrArabic(msg.text) ? 'right' : 'left' }}>
      {msg.status === 'pending' ? (
        <div className={styles.imageContainer}>
          <img src={msg.mediaUrl} alt="Sent" className={styles.photo} />
          {/* Circular Progress in middle of the image */}
          <CircularProgress progress={uploadProgress} />
        </div>
      ) : (
        <video crossOrigin="anonymous" controls src={msg.mediaUrl} className={styles.video} style={{ borderRadius: '15px', width: '200px', height: '200px' }} preload="auto" />
      )}
      {msg.text ? msg.text.split('\n').map((line, index) => (
        <span key={index}>{line}</span>
      )) : ''}
      <p>{msg.showTime} <strong>{msg.sender === 'me' && messageStatus(msg.status)}</strong></p>
    </div>
  )
};

export default VideoMessage;