import Modal from '../modal/Modal'
import styles from './filedownloading.module.scss'
const FileDownloading=({isOpen,closeModal,mediaurl})=>{
    // console.log('urllllllllll',mediaurl);
    
    return (
        <Modal isOpen={isOpen} isClose={closeModal}>
                <div style={{color:'white'}}>
                    <img src={mediaurl} alt="" style={{maxWidth:'900spx'}}/>
                </div>
                <button onClick={closeModal}>close</button>
                <a href={mediaurl} download>
                <button>Download</button>
            </a>
        </Modal>
    )
}
export default FileDownloading